<template>
  <div class="auth-form">
    <b-container class="mt-3 form-container" fluid="">
      <balance class="mb-3"></balance>
      <b-form @submit.prevent="save" >
        <b-form-group label="API KEY:" label-for="input-1">
          <b-form-input
              class="bx-form-input"
              v-model="config.apikey"
              type="text"
              required
              placeholder="API KEY"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Имя отправителя:" label-for="input-2">
          <b-form-input
              class="bx-form-input"
              v-model="config.sender"
              type="text"
              required
              placeholder="Имя отправителя"
          ></b-form-input>
        </b-form-group>

        <div class="d-flex mt-3">
          <button :disabled="loading" type="submit" class="btn-bx-succ">
            {{loading ? 'Сохранение': 'Сохранить'}}
            <b-spinner class="mx-2" small v-if="loading"></b-spinner>
          </button>
        </div>

      </b-form>
    </b-container>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import _ from "lodash";
import * as types from "../store/mutation-types";
import Balance from "./Balance";

export default {
  name: "Config",
  data() {
    return {
      loading: false,
      config: {
        apikey: '',
        sender: null,
      },
      sendersList: []
    }
  },
  async mounted() {
    await this.initConfig()
  },
  components: {
    Balance
  },
  computed: {
    ...mapGetters([
      'userLogin',
      'userPassword'
    ]),
  },
  methods: {
    ...mapMutations({
      setConfig: types.SET_CONFIG
    }),
    async save() {
      this.removeNotifications()
      this.loading = true
      try {
        const rawConfItem = await this.loadConfigItem()
        await this.$b24.Request.create('entity.item.update', {
          ENTITY: process.env.VUE_APP_BX_STORAGE_CONF_CODE,
          ID: _.get(rawConfItem, 'ID'),
          PROPERTY_VALUES: this.config
        }).execute()

        this.setConfig(_.clone(this.config))

        this.$toast.open({
          message: `Изменения сохранены`,
          type: 'success',
          position: 'top-right',
          duration: 3e3
        })
      } catch (e) {
        this.$log.error(e.message)
        this.$toast.open({
          message: `Ошибка сохранения изменений`,
          type: 'error',
          position: 'top-right',
        })
      } finally {
        this.loading = false
      }
    },
    /**
     * Убирает все открытые уведомления
     */
    removeNotifications() {
      this.$toast.clear();
    },
    /**
     * Вытаскивает конфиг приложения из хранилища битрикса
     * @param commit
     * @param getters
     * @param username
     * @param password
     * @param from
     * @param translit
     * @returns {Promise<void>}
     */
    async loadConfigItem () {
      this.$log.info(`Start loading app config`)

      const list = await this.$b24.Request.create('entity.item.get', {
        ENTITY: process.env.VUE_APP_BX_STORAGE_CONF_CODE,
        FILTER: {
          CODE: process.env.VUE_APP_BX_ITEM_CONF_CODE
        }
      }).execute()
      this.$log.info('Conf rows', list);

      const itemConf = _.head(list) || {};
      this.$log.info('Item config', itemConf)

      return itemConf
    },

    /**
     * Инициализация конфига приложения
     * @returns {Promise<void>}
     */
    async initConfig() {
      const rawConf = await this.loadConfigItem()
      const config = {
        apikey: _.get(rawConf, 'PROPERTY_VALUES.apikey'),
        sender: _.get(rawConf, 'PROPERTY_VALUES.sender'),
      }
      this.$log.info('DB config object', config)

      this.config = config
      this.setConfig(_.clone(config))
    }
  }
}
</script>

<style scoped lang="scss">
  .auth-form {

    .form-container {
      padding: 20px;
      background-color: white;

      .bx-form-input {
        border-radius: 0;
        box-shadow: none;

        &:active {
          border-color: rgba(59, 200, 245, .5);
        }

        &:hover {
          border-color: #8dc9e5;
        }


      }

      .btn-bx-succ {


        --ui-btn-size-xs: 26px;
        --ui-btn-size-sm: 31px;
        --ui-btn-size-md: 39px;
        --ui-btn-size-lg: 47px;
        --ui-btn-padding: 0 20px;
        --ui-btn-padding-right: 20px;
        --ui-btn-min-width: 80px;
        --ui-btn-height: var(--ui-btn-size-md);

        --ui-btn-background: #bbed21;
        --ui-btn-background-hover: #d2f95f;
        --ui-btn-background-active: #b2e232;
        --ui-btn-border-color: #bbed21;
        --ui-btn-border-color-hover: #d2f95f;
        --ui-btn-border-color-active: #b2e232;
        --ui-btn-opacity-after: .2;
        --ui-btn-colors-after-bg: #535c69;
        --ui-btn-colors-before-bg: #535c69;
        --ui-btn-color: #535c69;
        --ui-btn-color-hover: #535c69;
        --ui-btn-color-active: #535c69;

        border-radius: 2px;

        line-height: calc(var(--ui-btn-height) - 2px);
        vertical-align: middle;

        position: relative;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
        padding: var(--ui-btn-padding);
        color: var(--ui-btn-color);
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;
        font: var(--ui-btn-font-size)/calc(var(--ui-btn-height) - 2px) "OpenSans-Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: border-box;
        margin: 0;
        height: var(--ui-btn-height);
        outline: none;
        border: var(--ui-btn-border);
        border-color: var(--ui-btn-border-color);
        background-color: var(--ui-btn-background);
        box-shadow: var(--ui-btn-box-shadow);
        text-shadow: var(--ui-btn-text-shadow);
        cursor: pointer;
        transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 12px;

        &:hover {
          border-color: var(--ui-btn-border-color-hover);
          background-color: var(--ui-btn-background-hover);
          box-shadow: var(--ui-btn-box-shadow-hover);
          color: var(--ui-btn-color-hover);
          text-decoration: none;
          text-shadow: var(--ui-btn-text-shadow-hover);
        }
      }

      .btn-orange {
        background-color: #f66d13;
        color: white;
        border: none;

        &:hover {
          background-color: #ec5b07;
        }

      }

    }

  }
</style>